
import React, { Component } from 'react'
import { Modal,Form,Col,Input,Row,Radio, message} from 'antd';

import  UploadImage from "@/components/UploadImage.js"
import { updateEquipment } from "./service"

// const { Option } = Select;

/*
 *@Description: 叉车详情
 *@Author: Tp
 *@Date: 2020-09-10 18:53:59
*/
export class DetailUpdate extends Component {

    state = { visible: false,confirmLoading:false };

    formRef = React.createRef();

    avatarRef=React.createRef()

    constructor(props){
       super(props)

       console.log("组件详情")
    }


    handleCancel = e => {
      console.log(e);
      this.props.close(false)
    };

    handleComfirm(){
       this.setState({
         confirmLoading:true
       })
       let formValues=this.formRef.current.getFieldsValue()
       console.log(formValues)
      console.log(this.avatarRef.current.state)
      updateEquipment({
         equipmentName:formValues.equipmentName,
         state:formValues.state,
         id:this.props.detail.id,
         equipDiffer:formValues.equipDiffer
      },(res)=>{
         this.setState({
            confirmLoading:false
          })
         if(res){
            message.success(res.message)
            this.props.close(true)
         }
      })
    }
    //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){

    }

    render() {
        return (
            <>
            <Modal
              title="修改点火控制设备"
              visible={this.props.visible}
              destroyOnClose={true}
              onCancel={this.handleCancel}
              onOk={this.handleComfirm.bind(this)}
              width="600px"
              maskClosable={false}
              confirmLoading={this.state.confirmLoading}
            >
            <Form  layout='vertical'   ref={this.formRef}  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>


             <Form.Item label="设备名称" name="equipmentName" initialValue={this.props.detail.equipmentName}>
                     <Input placeholder="请输入设备名称" style={{width:'300px'}}></Input>
             </Form.Item>

             <Form.Item label="状态" name="state"  initialValue={this.props.detail.state}>
              <Radio.Group >
                <Radio value="0">正常</Radio>
                <Radio value="1">停用</Radio>
               </Radio.Group>
             </Form.Item>
             <Form.Item label="设备类型" name="equipDiffer" initialValue={this.props.detail.equipDiffer}>
                <Radio.Group >
                   <Radio value={0}>旧设备</Radio>
                   <Radio value={1}>新设备</Radio>
                  </Radio.Group>
                </Form.Item>

           <Row gutter={20}>
            <Col span={24} >
                  <Form.Item label="设备二维码">
                     <UploadImage ref={this.avatarRef}  limitNum={1}  fileList={[this.props.detail.qrCodePhoto]} />
                  </Form.Item>
               </Col>
           </Row>

          </Form>

     </Modal>
    </>
        )
    }
}

export default DetailUpdate

import React, { Component } from 'react'
import
{
  Skeleton,
  Form,
  Input,
  Table,
  Space,
  Button,
  Select
} from 'antd';

import { SearchOutlined,DownOutlined,UpOutlined} from "@ant-design/icons"
import {findType,findList } from "./service"
import { connect } from 'react-redux'

const { Option }=Select

export class SpotCheckRecord extends Component {
  //form表单
  formRef = React.createRef();
  //构造器
  constructor(props){
      super(props)
      this.state={
        loading:true,
        fold:true,
        tableData:[],
        contractTypeList:[],
        pagination:{
            current:1,//当前页面
            pageSize: 10, //页面尺寸
            total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        },
        dialogVisible:false,
        detail:{}
      }
  }
    //分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter, extra){
      if(extra.action==="paginate"){
         console.log("分页中")
         console.log(pagination)
         this.setState({
          pagination:{
              current:pagination.current,
              pageSize:pagination.pageSize,
              total: pagination.total,
              position:["bottomCenter"],
              showTotal:(total)=>`共 ${total} 条`
          }
         },()=>{
             this.handleQueryInfo()
         })
      }

  }
   componentDidMount(){

     this.handleQueryInfo()
     this.initContractType()
   }
       //表单提交
   onFormFinish(values){

    this.setState({
      pagination:{
          current:1,
          pageSize:10,
          total:0,
          position:["bottomCenter"],
          showTotal:(total)=>`共 ${total} 条`
      }
     },()=>{
         this.handleQueryInfo()
     })
    }

    initContractType(){
      findType({},res=>{
        console.log("合同类型")
        console.log(res)
        let contractTypeList=res.data.map(item=>{
            return <Option key={item.value} value={item.value}>{item.label}</Option>
        })
        this.setState({
          contractTypeList:contractTypeList
        })

      })
    }

    //查询数据
    handleQueryInfo(){

      let formValues=null

      if(this.formRef.current){
          formValues=this.formRef.current.getFieldsValue()
      }
      findList({
          currentPage:this.state.pagination.current,
          pageSize:this.state.pagination.pageSize,
          vehicleNo:formValues?formValues.vehicleNo:'',
          type:formValues?formValues.type:'',
          companyId: this.props.userInfo.id
      },(res)=>{
          console.log(res)
          if(!res){
               return
          }
          this.setState({
              loading:false,
              tableData:res.data.list.map((item,index)=>{
                item.key=index
                  return item
              }),
              pagination:{
                  current:this.state.pagination.current,
                  pageSize:this.state.pagination.pageSize,
                  total: res.data.total,
                  position:["bottomCenter"],
                  showTotal:(total)=>`共 ${total} 条`
              }
          })


      })
  }


  render() {
        //form布局
        const formLayout={
          layout:'inline',
          labelCol: { span: 8,offset:0 },
          wrapperCol: { span: 16 },
      }



      const columns=[
        {
          title:'单位名称',
          dataIndex:"company_name",
          align:"center",
        },{
          title:'叉车车牌号',
          dataIndex:"vehicle_no",
          align:"center",
        },
        {
          title:'合同开始时间',
          dataIndex:"start_date",
          align:"center",
        },
        {
          title:'合同结束时间',
          dataIndex:"end_date",
          align:"center",
        },
        {
          title:'合同类型',
          dataIndex:"label",
          align:"center",
        },
        {
          title:'包换次数',
          align:"center",
          render:(h)=>{
             return <span>{h.times}/3</span>
          }
        }
      ]

        return (
            <div className="shadow-radius">
               {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}
               {this.state.loading||<div className="layout">
                    <Form {...formLayout}  ref={this.formRef}   onFinish={this.onFormFinish.bind(this)}>
                          <Form.Item label="车辆牌号" name="vehicleNo"  className="form-item-margin">
                              <Input  placeholder="请输入车牌牌号"  />
                          </Form.Item>

                          <Form.Item label="合同类型" name="type" className="form-item-margin">
                             <Select style={{ width: 150 }}>
                                 {this.state
                                 .contractTypeList}
                             </Select>
                          </Form.Item>
                          <Form.Item  className="form-item-margin">
                           <Space>
                             <Button type="primary" icon={<SearchOutlined />} htmlType="submit">查询</Button>
                             {
                               this.state.fold?
                              <Button type="link"  onClick={()=>{ this.setState({fold:false}) }}>展开<DownOutlined /></Button>:
                              <Button type="link"  onClick={()=>{ this.setState({fold:true}) }} >收起<UpOutlined /></Button>
                             }
                             </Space>
                          </Form.Item>
                    </Form>

                    <Table columns={columns}   rowKey={record => record.key}  className="table-layout"  onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}></Table>

               </div>}
            </div>
        )
    }
}



const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(SpotCheckRecord);

/*
 * @Descripttion:
 * @Author: chentianlin
 * @Date: 2021-08-11 09:02:47
 * @LastEditors: chentianlin
 * @LastEditTime: 2021-08-11 09:24:14
 */
//生产环境
const  baseUrl="https://forklift-wxyd.hyjckj.net:8443"

export {
    baseUrl
}
